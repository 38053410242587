<template>
  <main>
    <div class="grid md:grid-cols-4 gap-4 mb-8">
      <job-summary-card label="All Jobs" :count="jobOverview?.all_jobs" job />
      <job-summary-card label="New Jobs" :count="jobOverview?.new_jobs" job />
      <job-summary-card label="In Progress" :count="jobOverview?.open_jobs" job />
      <job-summary-card label="Closed" :count="jobOverview?.closed_jobs" job />
    </div>

    <div class="grid md:grid-cols-4 place-content-between mb-7">
      <p class="text-lg text-[#687588] font-bold md:mt-3">Here’s all job list</p>
      <div class="md:col-span-3 place-self-end">
        <div class="grid md:grid-cols-4 grid-cols-2 md:gap-4 gap-y-4 gap-x-14">
          <div class="flex items-center justify-end">
            <span class="text-brand-black mr-2">{{ tab == 1 ? 'Normal' : 'Table' }} View</span>
            <div class="inline-flex items-center bg-[#F8F8F8] rounded-3xl p-1">
              <div
                class="w-[30px] h-[30px] rounded-full flex items-center justify-center cursor-pointer"
                :class="[tab === 1 ? 'bg-brand-black ' : 'bg-gray-300']"
                @click="tab = 1"
              >
                <img src="@/assets/icons/list.svg" alt="" />
              </div>
              <div
                class="w-[30px] h-[30px] rounded-full flex items-center justify-center cursor-pointer"
                :class="[tab === 2 ? 'bg-brand-black ' : 'bg-gray-300']"
                @click="tab = 2"
              >
                <img src="@/assets/icons/grid.svg" alt="" />
              </div>
            </div>
          </div>
          <form @submit.prevent="getAllJobs" class="relative w-full">
            <input
              type="search"
              class="block w-full px-4 py-3 pr-10 text-sm text-gray-900 border border-gray-300 rounded-md bg-gray-50"
              placeholder="Search jobs"
              v-model="filterKeys.search"
              required
            />

            <button class="absolute right-4 top-3 z-10">
              <img src="../../assets/icons/search.svg" alt="" />
            </button>
          </form>
          <tam-button
            class="bg-white hover:bg-white border border-light-grey rounded-xl"
            label="Filter"
            :icon="FilterLine"
            @click="showFilter = true"
          />
          <!-- <tam-button class="text-light-gray bg-white hover:bg-white border border-light-grey rounded-xl" label="Jun 01- Jun 30" :icon="Calendar" /> -->
          <tam-button
            class="bg-brand-black text-white rounded-xl text-[14px] hover:bg-brand-black"
            @click.prevent="
              actionType = 'add';
              jobToUpdateId = null;
              jobDialog = true;
            "
            color="secondary"
            label="Add Job"
            :icon="Cross"
          />
        </div>
      </div>
    </div>
    <div class="relative overflow-x-auto" v-if="pageLoaded">
      <div v-if="tab == 1">
        <div v-if="tableContent.length < 1" class="flex justify-center flex-col items-center mt-40">
          <p class="font-FoundersMedium text-black text-2xl mb-4">No Jobs available</p>
          <button
            class="bg-black rounded-md text-white py-4 px-8"
            @click.prevent="
              actionType = 'add';
              jobToUpdateId = null;
              jobDialog = true;
            "
          >
            Post a Job
          </button>
        </div>
        <div
          v-else
          class="bg-white p-6 rounded-2xl grid grid-rows-2 lg:flex items-center justify-between mb-4"
          v-for="item in tableContent"
          :key="item.uuid"
        >
          <div @click.prevent="viewJob(item)" class="cursor-pointer">
            <div class="flex items-center space-x-4 mb-2.5">
              <p class="text-lg font-bold text-[#1A1A1A]">{{ item?.role_title }}</p>
              <span
                :class="[
                  'px-4 py-1 text-[11px] rounded-md capitalize',
                  item?.status == 'open' ? 'text-[#00AD73] bg-[#E5FFF6]' : 'text-brand-danger bg-[#F8D7DA]',
                ]"
              >
                {{ item?.status }}
              </span>
            </div>
            <div class="text-[#8F8F8F] font-normal text-sm flex items-center gap-x-2.5 capitalize">
              <p>{{ formatString(item?.work_type) }}</p>
              <p class="mb-2 text-[#DEDEDE] text-base">.</p>
              <p>{{ item?.country?.name }}</p>
              <p class="mb-2 text-[#DEDEDE] text-base">.</p>
              <p>{{ formatString(item?.level_of_experience) }}</p>
            </div>
          </div>
          <div class="grid lg:grid-cols-4 gap-x-20">
            <div class="flex -space-x-4 rtl:space-x-reverse">
              <img class="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800" :src="item?.postedBy?.avatar_url" alt="" />
            </div>
            <div class="flex items-center space-x-4">
              <span class="text-sm text-[#687588] font-normal space-y-2">
                <p>{{ item?.applications_count }}</p>
                <p>Candidates</p>
              </span>
              <span class="text-sm text-[#687588] font-normal space-y-2">
                <p>{{ item?.shorlisted_count }}</p>
                <p>Shortlisted</p>
              </span>
            </div>

            <div class="flex items-center space-x-4">
              <span class="flex items-center text-sm text-[#687588] font-normal gap-2">
                <p>O</p>
                <p>Added {{ moment(item?.created_at).startOf('hour').fromNow() }}</p>
              </span>
            </div>
            <div class="flex justify-end space-x-4">
              <div class="flex items-center flex-nowrap">
                <button
                  v-tooltip.top="'Add candidate'"
                  class="w-[20px] h-[20px] cursor-pointer mr-2"
                  alt=""
                  @click="
                    selectedJob = item.uuid;
                    showAddCandidateToJob = true;
                  "
                >
                  <img src="@/assets/icons/plusline.svg" />
                </button>

                <button
                  v-tooltip.top="'Update'"
                  class="w-[20px] h-[20px] cursor-pointer mr-2"
                  @click.prevent="
                    actionType = 'update';
                    jobToUpdateId = item.uuid;
                    jobDialog = true;
                  "
                >
                  <img src="@/assets/icons/pencilline.svg" alt="" />
                </button>

                <button class="w-[20px] h-[20px] cursor-pointer mr-2" @click="shareJob(item)" v-tooltip.top="'Share'">
                  <img src="@/assets/icons/statusline.svg" alt="" />
                </button>

                <button class="w-[20px] h-[20px] cursor-pointer mr-2" @click.prevent="updateStatus(item.uuid)" v-tooltip.top="'Status update'">
                  <img src="@/assets/icons/trashline.svg" alt="" />
                </button>

                <button class="w-[20px] h-[20px] cursor-pointer" @click.prevent="cloneJob(item.uuid)" v-tooltip.top="'Clone'">
                  <img src="@/assets/icons/statusline.svg" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="tab == 2">
        <table class="w-full text-sm text-left text-gray-500 p-4">
          <thead class="uppercase bg-brand-black text-white">
            <tr>
              <th scope="col" class="px-6 py-4 text-[13px] whitespace-nowrap font-bold capitalize text-left" v-for="item in tableDetails" :key="item">
                {{ item }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tableContent" :key="item.uuid" class="bg-white border-b border-light-grey hover:bg-gray-50">
              <td v-if="checkbox" class="w-4 p-4">
                <div class="flex items-center">
                  <input
                    id="checkbox-table-1"
                    type="checkbox"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label for="checkbox-table-1" class="sr-only">checkbox</label>
                </div>
              </td>

              <td
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap cursor-pointer"
                @click.prevent="viewJob(item)"
              >
                <!-- :href="createJobLink(item)" -->
                <p>{{ item?.role_title }}</p>
                <div class="flex items-center mt-2">
                  <span class="capitalize inline-block text-light-gray text-[13px]">{{ formatString(item?.work_type) }}</span>
                  <img src="@/assets/icons/dot.svg" class="mx-2 w-[3.5px] h-[3.5px] inline-block" alt="" />
                  <span class="capitalize inline-block text-light-gray text-[13px]">{{ item?.country?.name }}</span>
                  <img src="@/assets/icons/dot.svg" class="mx-2 w-[3.5px] h-[3.5px] inline-block" alt="" />
                  <span class="capitalize inline-block text-light-gray text-[13px]">Exp - {{ formatString(item?.level_of_experience) }}</span>
                </div>
              </td>

              <td class="px-2 py-4">
                <span
                  :class="[
                    'px-4 py-1 text-[11px] rounded-md capitalize',
                    item?.status == 'open' ? 'text-[#00AD73] bg-[#E5FFF6]' : 'text-brand-danger bg-[#F8D7DA]',
                  ]"
                  >{{ item?.status }}</span
                >
              </td>

              <td class="px-6 py-4 text-gray-600 text-xs font-extralight">
                <div class="flex items-center">
                  <img :src="item?.postedBy?.avatar_url" alt="image" class="w-6 h-6 rounded-full mr-1.5" />
                  <span>{{ item?.postedBy?.name }}</span>
                </div>
              </td>
              <!-- <td scope="row" class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                {{ item?.job_title_name }}
              </td> -->

              <td class="px-6 py-4">
                <!-- :href="createJobLink(item)" -->
                <p>{{ item?.applications_count }}</p>
              </td>

              <td class="px-6 py-4 font-light">
                {{ moment(item?.created_at).format('D MMM, YYYY') }}
              </td>

              <td class="px-2 py-4 text-right">
                <div class="flex items-center flex-nowrap">
                  <button
                    v-tooltip.top="'Add candidate'"
                    class="w-[20px] h-[20px] cursor-pointer mr-2"
                    @click="
                      selectedJob = item.uuid;
                      showAddCandidateToJob = true;
                    "
                  >
                    <img src="@/assets/icons/plusline.svg" alt="" v-tooltip.top="'Add candidate'" />
                  </button>

                  <button
                    v-tooltip.top="'Update'"
                    class="w-[20px] h-[20px] cursor-pointer mr-2"
                    @click.prevent="
                      actionType = 'update';
                      jobToUpdateId = item.uuid;
                      jobDialog = true;
                    "
                  >
                    <img src="@/assets/icons/pencilline.svg" alt="" />
                  </button>

                  <button class="w-[20px] h-[20px] cursor-pointer mr-2" @click="shareJob(item)" v-tooltip.top="'Share'">
                    <img src="@/assets/icons/statusline.svg" alt="" />
                  </button>

                  <button v-tooltip.top="'Status update'" class="w-[20px] h-[20px] cursor-pointer mr-2" @click.prevent="updateStatus(item.uuid)">
                    <img src="@/assets/icons/trashline.svg" alt="" />
                  </button>

                  <button v-tooltip.top="'Clone'" class="w-[20px] h-[20px] cursor-pointer" @click.prevent="cloneJob(item.uuid)">
                    <img src="@/assets/icons/statusline.svg" alt="" />
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <Paginate
      :from="paginateContent.meta.from"
      :to="paginateContent.meta.to"
      :total="paginateContent.meta.total"
      :current="paginateContent.meta.current_page"
      :next="paginateContent.links.next"
      :prev="paginateContent.links.prev"
      :pages="paginateContent.meta.links"
      @move="doPaginate"
    />
    <ShareJob v-if="shareModal" :jobDetails="shareModalDetails" :user="user" @close="closeSharing" />
  </main>

  <jobs-filter
    :enums="enums"
    :open="showFilter"
    :months="months"
    :filter="filter"
    :filterVars="filterVars"
    @update="setFilterVar"
    @clear="clearFilter"
    @on-apply="getAllJobs"
    @on-close="showFilter = false"
  />

  <!-- <ConfirmDialog
    title-text="Delete Job"
    confirm-text="Are you sure you want to delete the selected job?"
    :is-active="deleteJobDialog"
    @confirm="deleteJob"
    @close="
      jobToUpdateId = null;
      deleteJobDialog = false;
    "
  /> -->

  <job-form
    v-if="jobDialog"
    :action_type="actionType"
    :jobId="jobToUpdateId"
    :is-clone="isClone"
    @on-close="
      jobToUpdateId = null;
      jobDialog = false;
      isClone = false;
    "
  />
  <invite-candidate-dialog v-if="inviteModal" :jobDetails="inviteModalDetails" @on-close="inviteModal = false" />

  <loading-overlay v-if="loading" />

  <change-status v-if="statusModal" @on-close="statusModal = false" :id="statusModalId" />

  <add-candidate-to-job-dialog
    :is-active="showAddCandidateToJob"
    :jobId="selectedJob"
    @close="
      selectedJob = null;
      showAddCandidateToJob = false;
    "
  />
</template>

<script setup>
import { FilterLine, Cross, Calendar, Download } from '@/icons';
import JobSummaryCard from '@/components/job/JobSummaryCard.vue';
import TamButton from '@/components/TamButton.vue';
import Paginate from '@/components/Paginate.vue';
import ShareJob from '@/components/ShareJobModal.vue';
import JobsFilter from '@/components/job/Filter.vue';
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import moment from 'moment';
import { useToast } from 'vue-toastification';
import { errorMessage, alert, role, createJobLink, formatString } from '@/utils/helper';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import JobForm from '@/components/job/JobFormDialog.vue';
import InviteCandidateDialog from '@/components/job/InviteCandidateDialog.vue';
import AddCandidateToJobDialog from '@/components/job/AddCandidateToJob.vue';
import ChangeStatus from './ChangeStatus.vue';

const router = useRouter();
const store = useStore();
const toast = useToast();
const tableDetails = ref(['role title', 'status', 'recruiters', 'applications', 'created date', 'action']);
const tab = ref(1);
const actionType = ref('add');
const jobToUpdateId = ref(null);
const selectedJob = ref(null);
const showAddCandidateToJob = ref(false);
const deleteJobDialog = ref(false);
const isClone = ref(false);
const jobDialog = ref(false);
const statusModal = ref(false);
const checkbox = ref(false);
const pageLoaded = ref(false);
const loading = ref(false);
const shareModal = ref(false);
const showFilter = ref(false);
const shareModalDetails = ref(null);
const inviteModal = ref(false);
const jobOverview = ref();
const inviteModalDetails = ref(null);
const statusModalId = ref(null);
const jobModalDetails = ref(null);
const filterVars = ref([]);
const filter = ref({
  search: '',
  date: '',
  status: '',
  job_status: '',
  min_experience: '',
  max_experience: '',
  work_type: '',
});

const filterKeys = ref({
  search: '',
  date: '',
  status: '',
  job_status: '',
  min_experience: '',
  max_experience: '',
  work_type: '',
});

const doPaginate = async ({ params }) => {
  await store.dispatch('global/getAllJobs', { params });
};

const enums = computed(() => {
  return store.getters['global/getEnumDetails'];
});

const inviteJob = (detail) => {
  inviteModalDetails.value = detail;
  inviteModal.value = true;
};

const updateStatus = (id) => {
  statusModalId.value = id;
  statusModal.value = true;
};

const viewJob = (detail) => {
  router.push(`/jobs/${detail.uuid}/${detail.role_title}`);
};

// const updateJob = (id) => {
//   console.log(id, 'THIS IS ID');
//   actionType.value = 'update';
//   jobToUpdateId.value = id;
//   jobDialog.value = true;
//   // console.log(details?.uuid);
//   // actionType.value = 'update';
//   // sessionStorage.setItem('actionType', 'update');
//   // sessionStorage.setItem('jobId', id);
//   // jobToUpdateId.value = details?.uuid;
// };

const closeSharing = () => {
  shareModal.value = false;
};

const shareJob = (job) => {
  shareModalDetails.value = job;
  shareModal.value = true;
};

const deleteJob = async (id) => {
  try {
    loading.value = true;
    const { message } = await store.dispatch('global/deleteSingleJob', id);
    alert(message, 'success');
    tableContent.value = await store.dispatch('global/getAllJobs');
    window.location.reload();
  } catch (error) {
    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  } finally {
    // jobToUpdateId.value = null;
    // deleteJobDialog.value = false;
    loading.value = false;
  }
};

const getCountry = (country) => {
  try {
    const values = Object.values(country);
    return values[2];
  } catch (error) {}
};

const exportJobs = async () => {
  try {
    loading.value = true;
    const data = await store.dispatch('global/exportJobOpenings');
    let csvContent = 'data:text/csv;charset=utf-8,' + data;
    let encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
    alert('Jobs File downloaded sucessfully', 'success');
  } catch (error) {
    alert(errorMessage(error), 'error');
  } finally {
    loading.value = false;
  }
};

const user = computed(() => {
  return store.getters['auth/userDetails'];
});

const tableContent = computed(() => {
  return store.getters['global/getAllJobs'];
});

const paginateContent = computed(() => {
  return store.getters['global/getJobsPayload'];
});

const setFilterVar = async (event, key, value = '') => {
  if (event == 'date-picked') {
    filterKeys.value[key] = value;
    filter.value[key] = value;
  } else if (event.target.type != 'checkbox') {
    filterKeys.value[key] = value ? value : event.target.value;
    filter.value[key] = value ? value : event.target.value;
  } else {
    filterKeys.value[key] = event.target.checked ? event.target.value : '';
    filter.value[key] = event.target.checked && value ? value : '';
  }

  const selected = Object.values(filter.value).filter((val) => val !== '') || [];
  filterVars.value = selected;
};

const clearFilter = async () => {
  filterKeys.value = filter.value = {
    date: '',
    status: '',
    job_status: '',
    min_experience: '',
    max_experience: '',
    work_type: '',
  };
  filterVars.value = [];
  await getAllJobs();
};

const getAllJobs = async () => {
  const params = Object.entries(filterKeys.value).reduce((a, [k, v]) => (v == '' ? a : ((a[k] = v), a)), {});
  try {
    loading.value = true;
    await store.dispatch('global/getAllJobs', { params });
  } catch (error) {
    alert(errorMessage(error), 'error');
  } finally {
    showFilter.value = false;
    loading.value = false;
  }
};

const cloneJob = (jobId) => {
  jobToUpdateId.value = jobId;
  jobDialog.value = true;
  isClone.value = true;
};

onMounted(async () => {
  sessionStorage.clear();
  await Promise.all([getAllJobs(), store.dispatch('global/getEnums')]);
  jobOverview.value = await store.dispatch('global/getJobOverviewCount');
  pageLoaded.value = true;
});
</script>
<style>
@media (max-width: 640px) {
  .sm\:relative {
    position: relative !important;
  }
}
</style>
